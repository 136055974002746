<template>
  <!-- 公司招聘信息共享 -->
  <div>
    <div class="banner">
      <div class="contentText">
        <div class="title">Internship，New Grad，公司招聘最新信息共享</div>
        <div class="subtitle">
          初创不易，希望大家提供自己知道的信息，北美求职，有你有我，我们一起走过
        </div>
      </div>
      <div class="searchArea">
        <el-input
          v-model="searchKey"
          style="width: 300px"
          placeholder="搜索公司"
          @input="searchLodash"
          prefix-icon="el-icon-search"
        ></el-input>
        <el-button type="primary" @click="openProvide">提供信息</el-button>
      </div>
    </div>
    <div class="tableArea">
      <el-table
        :data="tableData"
        style="
          width: 1200px;
          margin: 0 auto;
          margin-top: -32px;
          min-height: 500px;
          border-radius: 4px;
        "
        :key="itemKey"
      >
        <el-table-column>
          <template slot="header">
            <div>公司名称</div>
            <div>地点</div>
          </template>
          <template slot-scope="scope">
            <div class="companyContainer">
              <img class="companyLogo" :src="scope.row.logo" alt="" />
              <div>
                <div class="companyName" :title="scope.row.name">
                  {{ scope.row.name }}
                </div>
                <div class="companyDes" v-if="scope.row.careerFair">
                  {{ scope.row.address }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div>
              最新信息
              <el-popover
                placement="right"
                width="300"
                trigger="hover"
                style="display: inline-block; color: #909399"
              >
                <div class="contentContainer">
                  用户拿到OA，Phone，VO，Onsite等确准消息为主，或者内部信息
                  <br />
                  例：不Sponsor，招满，OA海发等
                </div>
                <div slot="reference" style="cursor: pointer">
                  <i class="el-icon-question"></i>
                </div>
              </el-popover>
              <i
                style="font-size: 20px; cursor: pointer; float: right"
                :class="[
                  orderType && order === '0'
                    ? 'el-icon-sort-up active'
                    : !orderType && order === '0'
                    ? 'el-icon-sort-down active'
                    : 'el-icon-sort',
                ]"
                @click="sortByMessage"
              ></i>
            </div>
          </template>
          <template slot-scope="scope">
            <el-popover
              placement="right"
              width="300"
              trigger="hover"
              v-if="
                scope.row.careerFair &&
                scope.row.careerFair.latestInformationList &&
                scope.row.careerFair.latestInformationList.length > 0
              "
            >
              <div class="contentContainer" v-if="scope.row.careerFair">
                <div
                  v-for="(comment, index) in scope.row.careerFair
                    .latestInformationList"
                  :key="index"
                  class="comment"
                >
                  {{ comment.content }}
                </div>
                <div style="text-align: center">
                  <el-button
                    type="primary"
                    class="submitBtn"
                    size="small"
                    @click="writeSuggestions(scope.row, 'suggestion')"
                    >提供信息</el-button
                  >
                </div>
              </div>
              <div
                slot="reference"
                class="jobFeelings"
                style="cursor: pointer"
                @click="jumpTrends(scope.row, '1')"
              >
                {{ scope.row.careerFair.latestInformationList[0].content }}
              </div>
            </el-popover>
            <div
              v-else
              class="applyLink"
              @click="writeSuggestions(scope.row, 'suggestion')"
            >
              提供信息
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div style="position: relative">
              <div class="orange">New Grad Salary /y</div>
              <div class="orange">New Grad Link</div>
              <i
                style="
                  font-size: 20px;
                  cursor: pointer;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                "
                :class="[
                  orderType && order === '1'
                    ? 'el-icon-sort-up active'
                    : !orderType && order === '1'
                    ? 'el-icon-sort-down active'
                    : 'el-icon-sort',
                ]"
                @click="sortByNewGrad"
              ></i>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="orange"
              v-if="
                scope.row.careerFair &&
                scope.row.careerFair.details['1'] &&
                scope.row.careerFair.details['1'].annualSalary
              "
            >
              {{ !scope.row.isDomestic ? "$" : "￥"
              }}{{ scope.row.careerFair.details["1"].annualSalary }} /y
            </div>
            <div
              class="orange"
              v-else
              style="
                cursor: pointer;
                text-decoration: underline;
                display: inline-block;
              "
              @click="provideResource(scope.row)"
            >
              提供信息
            </div>
            <div class="newGrad" style="cursor: pointer">
              <div
                class="applyLink"
                v-if="
                  scope.row.careerFair &&
                  scope.row.careerFair.details['1'] &&
                  scope.row.careerFair.details['1'].link
                "
                @click="jumpLink(scope.row.careerFair.details['1'].link)"
              >
                Apply&nbsp;→
              </div>
              <div
                class="notOpenLink"
                v-else
                @click="jumpLink(scope.row.careerFair.link)"
              >
                Unknown
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <div style="position: relative">
              <div class="blue">Internship Salary /m</div>
              <div class="blue">Internship Link</div>
              <i
                style="
                  font-size: 20px;
                  cursor: pointer;
                  position: absolute;
                  right: 0;
                  top: 50%;
                  transform: translateY(-50%);
                "
                :class="[
                  orderType && order === '2'
                    ? 'el-icon-sort-up active'
                    : !orderType && order === '2'
                    ? 'el-icon-sort-down active'
                    : 'el-icon-sort',
                ]"
                @click="sortByInternship"
              ></i>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="blue"
              v-if="
                scope.row.careerFair &&
                scope.row.careerFair.details['0'] &&
                scope.row.careerFair.details['0'].monthlySalary
              "
            >
              {{ !scope.row.isDomestic ? "$" : "￥"
              }}{{ scope.row.careerFair.details["0"].monthlySalary }} /m
            </div>
            <div
              class="blue"
              v-else
              style="
                cursor: pointer;
                text-decoration: underline;
                display: inline-block;
              "
              @click="provideResource(scope.row)"
            >
              提供信息
            </div>
            <div class="internship">
              <div
                class="applyLink"
                v-if="
                  scope.row.careerFair &&
                  scope.row.careerFair.details['0'] &&
                  scope.row.careerFair.details['0'].link
                "
                @click="jumpLink(scope.row.careerFair.details['0'].link)"
              >
                Apply&nbsp;→
              </div>
              <div
                class="notOpenLink"
                v-else
                @click="jumpLink(scope.row.careerFair.link)"
              >
                Unknown
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="careerFair.jobFeelings">
          <template slot="header">
            求职感受
            <el-popover
              placement="right"
              width="300"
              trigger="hover"
              style="display: inline-block; color: #909399"
            >
              <div class="contentContainer">
                投简历后的求职体验，投了没反应默拒，Bar高，面试内容与难度，吐槽，等等
              </div>
              <div slot="reference" style="cursor: pointer">
                <i class="el-icon-question"></i>
              </div>
            </el-popover>
          </template>
          <template slot-scope="scope" v-if="scope.row.careerFair">
            <el-popover
              placement="right"
              width="300"
              trigger="hover"
              v-if="scope.row.careerFair && scope.row.careerFair.jobFeelings"
            >
              <div class="contentContainer">
                <div
                  v-for="(comment, index) in scope.row.careerFair
                    .jobFeelingList"
                  :key="index"
                  class="comment"
                >
                  {{ comment.content }}
                </div>
                <div style="text-align: center">
                  <el-button
                    type="primary"
                    class="submitBtn"
                    size="small"
                    @click="writeSuggestions(scope.row, 'expression')"
                    >写感受</el-button
                  >
                </div>
              </div>
              <div
                slot="reference"
                class="jobFeelings"
                style="cursor: pointer"
                @click="jumpTrends(scope.row, '0')"
              >
                {{ scope.row.careerFair && scope.row.careerFair.jobFeelings }}
              </div>
            </el-popover>
            <div
              v-else
              class="applyLink"
              style="display: inline-block"
              @click="writeSuggestions(scope.row, 'expression')"
            >
              写感受
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="padding: 30px; margin-top: 12px; text-align: center"
        prev-text="上一页"
        next-text="下一页"
        :current-page.sync="currentPage"
        :total="total"
        layout="prev, pager, next"
        :page-size="50"
        @current-change="getResources"
      >
      </el-pagination>
      <el-dialog
        :title="writeType == 'suggestion' ? '提供信息' : '求职感受'"
        :visible.sync="dialogVisible"
        top="25vh"
		 :close-on-click-modal="false"
        custom-class="writeSug"
      >
        <el-form label-width="100px">
          <el-form-item label="类型">
            <el-radio-group v-model="suggestionType" size="small">
              <el-radio :label="0" border>Internship</el-radio>
              <el-radio :label="1" border>New Grad</el-radio>
              <el-radio :label="2" border>在职跳槽</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            :label="writeType == 'suggestion' ? '信息内容' : '求职感受'"
          >
            <el-input type="textarea" v-model="suggestionText"></el-input>
          </el-form-item>
          <el-form-item label="匿名发布">
            <el-checkbox v-model="anonymity"></el-checkbox>
          </el-form-item>
        </el-form>
        <div slot="footer" style="text-align: center">
          <el-button type="primary" @click="submitSuggestion">提交</el-button>
        </div>
      </el-dialog>
      <el-dialog :visible.sync="provideDialogVisible"  :close-on-click-modal="false">
        <div class="provideContainer">
          <el-button class="provideBtn" @click="jumpProvide('newCompany')"
            >提供新的公司</el-button
          >
          <el-button class="provideBtn" @click="jumpProvide('newResource')"
            >提供求职最新信息</el-button
          >
          <el-button class="provideBtn" @click="jumpProvide('editResource')"
            >补充更正已有公司信息</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { getCompanyResource } from "@/service/company";
import { postSuggestion } from "@/service/provideResource";
import _ from "lodash";
import { mapState } from "vuex";
export default {
  name: "RecruitmentResourceSharing",
  data() {
    return {
      searchKey: "",
      currentPage: 1,
      total: 0,
      tableData: [],
      currentId: "",
      suggestionText: "",
      dialogVisible: false,
      currentTitle: "",
      provideDialogVisible: false,
      pageSize: 50,
      suggestionType: 0,
      writeType: "",
      order: "",
      orderType: null,
      itemKey: Math.random(),
      anonymity: false,
    };
  },
  methods: {
    getResources(page) {
      if (page) {
        if (!this.userInfo || !this.userInfo.id) {
          this.$router.push("/registerform");
        }
        this.currentPage = page;
      } else {
        this.currentPage = 1;
      }
      getCompanyResource({
        current: page ? page : 1,
        name: this.searchKey,
        size: 50,
        order: this.order ? this.order : "",
        orderType: this.orderType ? this.orderType : "",
      }).then((res) => {
        if (res.success) {
          window.scrollTo(0, 0);
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    jumpLink(url) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
      }
      window.open(url);
    },
    loginBtn() {
      this.$router.push("/registerform");
    },
    jumpProvide(type) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
      }
      this.$router.push(`/provideResource?type=${type}`);
    },
    searchLodash: _.debounce(function () {
      this.getResources(1);
    }, 1000),
    writeSuggestions(item, type) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
      }
      this.writeType = type;
      this.currentId = item.id;
      this.dialogVisible = true;
    },
    submitSuggestion() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
      }
      let data = {
        companyId: this.currentId,
        type: this.writeType == "suggestion" ? 1 : 0,
        jobType: this.suggestionType,
        content: this.suggestionText,
        anonymity: this.anonymity,
      };
      postSuggestion(data).then((res) => {
        if (res.success) {
          this.dialogVisible = false;
          this.$message.success("已收到您的反馈!");
          this.suggestionType = 0;
          this.suggestionText = "";
          this.anonymity = false;
          this.getResources();
        }
      });
    },
    provideResource(item) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
      }
      this.$router.push(
        `/provideResource?type=editResource&companyId=${item.id}&companyName=${item.name}`
      );
    },
    sortByMessage() {
      if (this.orderType === null) {
        this.orderType = true;
      } else {
        this.orderType = !this.orderType;
      }
      this.order = "0";
      this.$nextTick(() => {
        this.itemKey = Math.random();
        this.$forceUpdate();
      });
    },
    sortByNewGrad() {
      if (this.orderType === null) {
        this.orderType = true;
      } else {
        this.orderType = !this.orderType;
      }
      this.order = "1";
      this.$nextTick(() => {
        this.itemKey = Math.random();
        this.$forceUpdate();
      });
    },
    sortByInternship() {
      if (this.orderType === null) {
        this.orderType = true;
      } else {
        this.orderType = !this.orderType;
      }
      this.order = "2";
      this.$nextTick(() => {
        this.itemKey = Math.random();
        this.$forceUpdate();
      });
    },
    openProvide() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.provideDialogVisible = true;
    },
    jumpTrends(item, jobType) {
      console.log(item, jobType);
      this.$router.push(
        `/trends?type=findJobs&companyId=${item.id}&companyName=${item.name}&jobType=${jobType}`
      );
    },
  },
  mounted() {
    this.getResources();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    order(val) {
      getCompanyResource({
        current: this.currentPage,
        name: this.searchKey,
        size: 50,
        order: val,
        orderType: this.orderType,
      }).then((res) => {
        if (res.success) {
          window.scrollTo(0, 0);
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
    orderType(val) {
      getCompanyResource({
        current: this.currentPage,
        name: this.searchKey,
        size: 50,
        order: this.order,
        orderType: val,
      }).then((res) => {
        if (res.success) {
          window.scrollTo(0, 0);
          this.tableData = res.result.records;
          this.total = res.result.total;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.banner {
  width: 100%;
  background: url(../../assets/img/recruitmentResource.png) no-repeat;
  background-size: 100% 100%;
  height: 320px;
  .contentText {
    text-align: center;
    color: #fff;
    padding-top: 108px;
    .title {
      font-size: 28px;
    }
    .subtitle {
      font-size: 14px;
    }
  }
  .searchArea {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 52px;
  }
}
.orange {
  color: rgba(250, 100, 0, 1);
  font-size: 14px;
}
.blue {
  color: rgba(10, 122, 255, 1);
  font-size: 14px;
}
.companyContainer {
  display: flex;
  padding: 20px 0;
  .companyLogo {
    width: 30px;
    height: 30px;
    margin-right: 12px;
    vertical-align: middle;
    transform: translateY(6px);
  }
  .companyName {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 21px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .companyDes {
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.8);
    line-height: 18px;
  }
}
.applyLink {
  padding: 4px 8px;
  display: inline-block;
  background: rgba(10, 122, 255, 0.3);
  border-radius: 12px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #0a7aff;
  line-height: 18px;
  cursor: pointer;
}
.notOpenLink {
  padding: 4px 8px;
  display: inline-block;
  background: rgba(118, 118, 128, 0.24);
  border-radius: 12px;
  font-size: 12px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #757575;
  line-height: 18px;
  cursor: pointer;
}
::v-deep .el-table th {
  background: #f4f4f4;
}
.loginTip {
  text-align: center;
  padding: 30px;
}
.provideBtn {
  width: 80%;
  margin: 0 10%;
}
.provideContainer .el-button {
  margin-bottom: 24px;
  &:hover {
    background: #0a7aff;
    color: #fff;
  }
}
.contentContainer {
  padding: 24px;
  .comment {
    width: 100%;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    text-decoration: underline;
    margin-bottom: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.el-radio.is-bordered {
  ::v-deep .el-radio__input {
    display: none;
  }
  ::v-deep .el-radio__label {
    padding-left: 0;
  }
}
::v-deep .el-radio--small.is-bordered {
  padding: 8px 10px;
  border-radius: 16px;
}
::v-deep .el-radio {
  margin-right: 0px;
}
.submitBtn {
  width: 160px;
  border-radius: 16px;
  margin: 0 auto;
}
::v-deep .el-table th {
  padding-left: 30px;
}
::v-deep .el-table td {
  padding-left: 30px;
}
::v-deep .el-dialog {
  border-radius: 6px;
  width: 30%;
}
.jobFeelings {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
::v-deep .writeSug .el-dialog__body {
  padding-bottom: 0px;
}
.active {
  color: #0a7aff;
}
</style>