// 提供资源信息
import request from '../utils/request';

// 提供建议
export async function postSuggestion(data) {
    try {
        return await request({
            url: `/posts/createJobFeeling`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 创建公司
export async function createCompany(data) {
    try {
        return await request({
            url: `/company/createCompany`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 编辑公司
export async function editCompany(data) {
    try {
        return await request({
            url: `/company/editCompany`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}